import ReactTimeAgo from "react-time-ago";

export const LastSeen = ({ date }: { date: Date }) => {
  return (
    <div>
      <p className="text-white font-normal justify-self-end ">
        Last updated{" "}
        <ReactTimeAgo date={date} locale="en-US" timeStyle="twitter-minute" />
      </p>
    </div>
  );
};
