import { Pie } from "react-chartjs-2";
import { WidgetType } from "../../types/widgets";
import { FC, useState, useEffect } from "react";
import { Chart, LegendItem } from "chart.js";

interface LineInterface {
  widget: WidgetType;
}
export const PieWidget: FC<LineInterface> = ({ widget }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const colors: string[] = [];

  for (let i = 0; i < Object.keys(widget.pieValues).length; i++) {
    colors.push(
      "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0")
    );
  }
  const dataArray = Object.entries(widget.pieValues);

  dataArray.sort((a, b) => b[1] - a[1]);
  const sum = dataArray.reduce((acc, current) => acc + current[1], 0);

  const sortedData = Object.fromEntries(dataArray);
  const data = {
    labels: Object.keys(sortedData),
    datasets: [
      {
        label: "",
        data: Object.values(sortedData),
        backgroundColor: colors,
        borderColor: "white",
        borderWidth: 0.1,
      },
    ],
  };

  const truncateLabel = (str: string, maxLength = 15) => {
    if (str.length <= maxLength) return str;
    return str.substr(0, maxLength) + "...";
  };

  return (
    <div className="h-[80%] w-[99%] z-10 flex flex-col justify-end  ">
      <div className="w-full flex justify-between">
        <p className="w-[55px]"></p>
        <h2 className="text-white text-xl self-center ">{widget.title}</h2>
        <p className="w-[55px] text-white break-keep">
          {Intl.NumberFormat("en-US", {
            notation: "compact",
            maximumFractionDigits: 1,
            minimumFractionDigits: 1,
          }).format(sum)}
        </p>
      </div>
      <Pie
        data={data}
        options={{
          layout: {
            padding: {
              left: -7 * Object.keys(widget.pieValues).length,
            },
          },
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: "right",
              labels: {
                usePointStyle: true,
                pointStyle: "circle",
                color: "white !important",
                font: {
                  size:windowWidth > 2000 ? 18 :  14,
                },
                generateLabels: (chart: Chart): LegendItem[] => {
                  const data = chart.data;
                  if (data.labels && data.datasets) {
                    return data.labels.map((label, i) => {
                      const labelText =
                        typeof label === "string"
                          ? label
                          : (label as string).toString();
                      return {
                        text: truncateLabel(labelText),
                        fontColor: "white",
                        fillStyle: colors[i],
                        hidden: !chart.getDataVisibility(i),
                        lineCap: "butt",
                        lineDash: [],
                        lineDashOffset: 0,
                        lineJoin: "bevel",
                        lineWidth: 0,
                        strokeStyle: "",
                        pointStyle: "circle",
                        datasetIndex: i,
                        index: i,
                      };
                    });
                  }
                  return [];
                },
              },
            },
          },
        }}
      />
    </div>
  );
};
