import { Line } from "react-chartjs-2";
import { WidgetType } from "../../types/widgets";
import { FC } from "react";

interface LineInterface {
widget: WidgetType
}

export const LineWidget: FC<LineInterface> = ({widget}) => {
  let datasets: any = [];
  let colors = [
    "#7bb6ec",
    "#47bbb3",
    "#f48023",
    "#fbbd05",
    "#6d4646",
    "#04000e",
    "#fecea8",
    "#7070ff",
  ];
  for (let i = 0; i < widget.series.length; i++) {
    datasets.push({
      label: widget.series[i].name,
      data: widget.series[i].data,
      borderColor: "#fff",
      backgroundColor: colors[i],
      borderWidth: 1,
    });
  }
  return (
    <div className="relative w-[90%] h-[90%]">
      <Line
        datasetIdKey={widget.id}
        data={{
          labels: widget.categories,
          datasets: datasets,
        }}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            tooltip: {
              enabled: widget.plotOptions.line.enableMouseTracking,
            },
            legend: {
              display: widget.legend_position !== "none",
              position:
                widget.legend_position !== "none" && widget.legend_position,
              labels: {
                color: widget.bgcolor === "#000" ? "white" : "black",
                usePointStyle: true,
                pointStyle: 'circle'
                
              },
            },
            title: {
              position: "bottom",
              display: true,
              text: widget.title,
              font: {
                family:
                  '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                size: 30,
                weight: "lighter",
              },
              color: "white",
            },
          },
          scales: {
            x: {
              stacked: widget.is_stacked,
              grid: {
                display: false,
              },
              ticks: {
                color: "white",
              },
            },
            y: {
              stacked: widget.is_stacked,
              grid: {
                color: "#ffffff96",
              },
              ticks: {
                color: "white",
              },
            },
          },
        }}
      />
    </div>
  );
};
