import { useEffect, useRef } from "react";

export const Counter = ({
  from,
  to,
  size,
  wholeNumber,
  textColor
}: {
  from: number;
  to: number;
  size: number;
  wholeNumber?: boolean;
  textColor: string
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const sizeVariants: Record<number, string> = {
    75: "text-[40px] md:text-[75px]",
    27: "text-[27px]",
  };

  useEffect(() => {
    const startTime = Date.now();
    const duration = 500;

    const interval = setInterval(() => {
      const progress = Math.min(1, (Date.now() - startTime) / duration);
      const currentAnimationValue = from + (to - from) * progress;

      if (ref.current) {

        ref.current.textContent = wholeNumber || to % 1 === 0
          ? Math.floor(currentAnimationValue).toLocaleString()
          :
          /*
          currentAnimationValue.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })*/
          Intl.NumberFormat('en-US', { notation: "compact", maximumFractionDigits: 1, minimumFractionDigits: 1 }).format(currentAnimationValue)
          ;
      }

      if (progress === 1) {
        clearInterval(interval);
      }
    }, 16);

    return () => {
      clearInterval(interval);
    };
  }, [from, to, wholeNumber]);

  return <div style={{ color: textColor }} className={`text-white font-light  ${sizeVariants[size]}`} ref={ref} />;
};
