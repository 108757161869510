import { Dashboard } from "./components/dashboard";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import ReactGA from 'react-ga4';
import React, { useEffect } from 'react';
import { Analytics } from '@vercel/analytics/react';

ReactGA.initialize('G-FPWVF29C35');

function App() {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/" });
  }, []);

  TimeAgo.addDefaultLocale(en)

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dashboard />} />
        </Routes>
      </BrowserRouter>
      <Analytics />
    </div>
  );
}

export default App;
