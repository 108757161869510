import {
  Chart,
  Title,
  Legend,
  Tooltip,
  BarElement,
  LinearScale,
  LineElement,
  PointElement,
  CategoryScale,
  LineController,
  ArcElement,
} from "chart.js";
import {
  WidgetType,
  WidgetApiType,
  WidgetPositionType,
} from "../types/widgets";
import axios from "axios";
import Swal from "sweetalert2";
import { Counter } from "./counter";
import GaugeChart from "react-gauge-chart";
import { FaArrowUp } from "react-icons/fa";
import { useState, useEffect } from "react";
import { FaExternalLinkSquareAlt } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa";
import { DashboardWidgetType } from "../types/dashboardWidget";
import withReactContent from "sweetalert2-react-content";
import { Layout, Responsive, WidthProvider } from "react-grid-layout";
import { useSearchParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { LineWidget } from "./kpiType/Line";
import { BarWidget } from "./kpiType/Bar";
import { TableWidget } from "./kpiType/Table";
import { PieWidget } from "./kpiType/Pie";
import "react-loading-skeleton/dist/skeleton.css";
import "react-resizable/css/styles.css";
import "react-grid-layout/css/styles.css";
import { LastSeen } from "./lastSeen";

const MySwal = withReactContent(Swal);
const ResponsiveReactGridLayout = WidthProvider(Responsive);

export const Dashboard = () => {
  const [gridLayouts, setGridLayouts] = useState();
  const [callApi, setCallApi] = useState<boolean>(false);
  const [dashboard, setDashboard] = useState<DashboardWidgetType>();
  const [widgetArray, setWidgetArray] = useState<WidgetType[]>([]);
  const [widgetArrayPosition, setWidgetArrayPosition] = useState<
    WidgetPositionType[]
  >([]);

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const baseUrl = "https://api-prod.tradepeg.com/reports";

  // for charts
  Chart.register(
    Title,
    Legend,
    Tooltip,
    ArcElement,
    BarElement,
    LineElement,
    LinearScale,
    PointElement,
    CategoryScale,
    LineController
  );

  // get all widgets position and widgets value
  const getWidgetsValues = async (
    widgets: WidgetPositionType[] = widgetArrayPosition
  ) => {
    try {
      const refreshWidget: WidgetType[] = [];
      await Promise.all(
        widgets.map(async (widget: WidgetPositionType, index: number) => {
          const responseWidget = await axios.get(
            `${baseUrl}/kpi-dashboard/${token}/value/${widget.id}`
          );
          if (widgetArray.length >= widgets.length) {
            refreshWidget.push({ ...responseWidget.data, id: widget.id });
          } else {
            setWidgetArray((prevState) => [
              ...prevState,
              { ...responseWidget.data, id: widget.id },
            ]);
          }
        })
      );
      if (refreshWidget.length > 0) {
        setWidgetArray(refreshWidget);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDataDashboards = async () => {
    try {
      if (token == null || token.length === 0) throw new Error("Token is null");

      // get dashboard data
      const responseDashboard = await axios.get(
        `${baseUrl}/kpi-dashboard/${token}`
      );

      let widgets = responseDashboard.data.data.widgets;
      setWidgetArrayPosition(widgets);

      setDashboard(responseDashboard.data.data);
      document.title = `Dashboard - ${responseDashboard.data.data.title}`;

      getWidgetsValues(widgets);
    } catch (error) {
      console.log(error);
      MySwal.fire({
        title: "Error!",
        text: "Something went wrong whilst loading the dashboard",
        icon: "error",
        confirmButtonText: "Retry!",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    }
  };

  useEffect(() => {
    getDataDashboards();

    const interval = setTimeout(() => setCallApi(!callApi), 60000);
    return () => {
      clearTimeout(interval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callApi]);

  // function to modify the position of the widgets
  const handleModify = async (layouts: any, layout: any) => {
    try {
      const tempArray = widgetArrayPosition;
      setGridLayouts(layout);

      layouts?.forEach((position: Layout) => {
        tempArray[Number(position.i)].col = position.x;
        tempArray[Number(position.i)].row = position.y;
        tempArray[Number(position.i)].width = position.w;
        tempArray[Number(position.i)].height = position.h;
        tempArray[Number(position.i)].id = tempArray[Number(position.i)].id;
      });

      setWidgetArrayPosition(tempArray);
    } catch (error) {
      console.log(error);
    }
  };

  // function to send position of widgets to api
  const onDrop = async (layout: Layout[]) => {
    try {
      const apiArray: WidgetApiType[] = [];
      layout.forEach((element: Layout, i: number) => {
        apiArray.push({
          col: element.x,
          row: element.y,
          id: widgetArrayPosition[i].id,
          width: element.w,
          height: element.h,
        });
      });

      const response = await axios.put(
        `${baseUrl}/kpi-dashboard/${token}/widgets`,
        apiArray
      );

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let data = response.data;
    } catch (error) {
      console.log(error);
      MySwal.fire({
        title: "Error!",
        text: "Something went wrong whilst updating the dashboard",
        icon: "error",
        confirmButtonText: "Retry!",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    }
  };

  return (
    <div className="bg-[#222] min-h-[100vh]">
      <h1 className="font-normal text-[45px] mb-5 text-white">
        {dashboard?.title}
      </h1>
      {widgetArrayPosition.length ? (
        <ResponsiveReactGridLayout
          onLayoutChange={handleModify}
          layouts={gridLayouts}
          rowHeight={300}
          onDragStop={onDrop}
          onResizeStop={onDrop}
          breakpoints={{ lg: 1024, xxs: 0 }}
          preventCollision={false}
          cols={{ lg: dashboard!.columns, xxs: 1 }}
          autoSize={true}
          margin={{
            lg: [10, 10],
            xxs: [10, 10],
          }}
        >
          {widgetArrayPosition?.map((widgetPosition, index) => {
            const widget = widgetArray.filter(
              (widget) => widget.id === widgetArrayPosition[index].id
            )[0];

            if (widget && !widget.bgcolor) {
              if (widget.type === "Number") {
                widget.bgcolor = "#47bbb3";
              } else {
                widget.bgcolor = "#47bbb3";
              }
            }
            if (widget && "alert_widget" in widget && widget.alert_widget) {
              widget.bgcolor = "#915555";
            }

            return (
              <div
                style={{ backgroundColor: widget && widget.bgcolor }}
                className={`reactGridItem ${
                  widget && widget.alert_widget && "alert-pulse"
                } flex flex-col items-center justify-between w-full  relative p-3 rounded-[20px] bg-[#47bbb3]`}
                key={index}
                data-grid={{
                  x: widgetArrayPosition[index].col,
                  y: widgetArrayPosition[index].row,
                  w: widgetArrayPosition[index].width,
                  h: widgetArrayPosition[index].height,
                  i: widgetArrayPosition[index].id,
                  minW: 1,
                  maxW: Infinity,
                  minH: 1,
                  maxH: Infinity,
                  isDraggable: !dashboard?.locked,
                  isResizable: !dashboard?.locked,
                }}
              >
                {widget ? (
                  widgetPosition.type !== "Gauge" && (
                    <div className="self-end flex items-center text-white ">
                      {widget.last ? (
                        <>
                          {widget.current < widget.last ? (
                            <span className="text-red-600 text-xl">
                              <FaArrowDown />
                            </span>
                          ) : (
                            <span className="text-green-500">
                              <FaArrowUp />
                            </span>
                          )}
                          <div className="flex items-center gap-0.5 font-semibold ml-1.5 mr-3">
                            <Counter
                              from={0}
                              to={Math.floor(
                                Math.abs(
                                  (widget.current - widget.last) / widget.last
                                ) * 100
                              )}
                              size={27}
                              wholeNumber={true}
                              textColor={widget.forecolor}
                            />
                            <span className="self-end mb-1"> % </span>
                          </div>
                        </>
                      ) : (
                        <div>
                          {widgetPosition.type === "Number" && (
                            <div className="h-[35px]"></div>
                          )}
                        </div>
                      )}
                      {widget.last && (
                        <p className="text-sm self-end mb-[4.2px]">
                          {Intl.NumberFormat("en-US", {
                            notation: "compact",
                            maximumFractionDigits: 1,
                          }).format(widget.last)}
                        </p>
                      )}
                    </div>
                  )
                ) : (
                  <div className="flex justify-end items-end w-full mr-2">
                    <Skeleton
                      count={1}
                      height={30}
                      width={80}
                      baseColor="#47bbb3"
                      highlightColor="#13827a"
                    />
                  </div>
                )}
                {(() => {
                  if (widget) {
                    if (widgetPosition.type === "Gauge" && widget.max) {
                      let percentage = 0;
                      if (widget) {
                        percentage =
                          Math.round((widget.current / widget.max) * 1000) /
                          1000;
                      }
                      return (
                        <>
                          <div>
                            <div className="flex flex-col justify-between">
                              <GaugeChart
                                style={{
                                  width: `calc(400px * ${
                                    widgetPosition.width ===
                                    widgetPosition.height
                                      ? widgetPosition.width
                                      : 1
                                  })`,
                                  height: `calc(150px * ${widgetPosition.height})`,
                                }}
                                id={`${widget.id}`}
                                nrOfLevels={2}
                                arcsLength={[percentage, 1 - percentage]}
                                cornerRadius={2}
                                colors={["#5BE12C", "#F5CD19", "#EA4228"]}
                                needleColor={"white"}
                                needleBaseColor={"white"}
                                percent={percentage}
                                arcPadding={0.005}
                              />
                            </div>
                            <div className="clearfix"></div>
                            <h3 className="text-white text-[35px] font-light ">
                              {widget.title} :
                              {Intl.NumberFormat("en-US", {
                                notation: "compact",
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              }).format(widget.current)}
                              /
                              {Intl.NumberFormat("en-US", {
                                notation: "compact",
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              }).format(widget.max)}
                            </h3>
                          </div>
                        </>
                      );
                    } else if (widgetPosition.type === "Number") {
                      return (
                        <div className="flex flex-col  items-start self-start h-[210px]">
                          {widget.current !== null ? (
                            <Counter
                              to={widget.current}
                              from={0}
                              size={75}
                              wholeNumber={widget.whole_number}
                              textColor={widget.forecolor}
                            />
                          ) : (
                            <div className="h-[105px]"></div>
                          )}
                          <h3
                            style={{
                              backgroundColor: widget.alert_widget
                                ? ""
                                : widget.bgcolor,
                            }}
                            className={`text-left text-[30px] text-white hover:rounded-lg font-light  line-clamp-2 hover:line-clamp-none hover:z-20 break-words`}
                          >
                            {widget.title}
                          </h3>
                        </div>
                      );
                    } else if (widget.type === "column") {
                      return <BarWidget widget={widget} />;
                    } else if (widget.type === "line") {
                      return <LineWidget widget={widget} />;
                    } else if (widgetPosition.type === "Pie") {
                      return <PieWidget widget={widget} />;
                    } else if (widgetPosition.type === "Table") {
                      return <TableWidget widget={widget} />;
                    }
                  } else {
                    return (
                      <div className="my-5 w-full h-full">
                        <Skeleton
                          height={170}
                          baseColor="#47bbb3"
                          highlightColor="#13827a"
                        />
                      </div>
                    );
                  }
                })()}

                <div className="flex flex-col gap-0.5  items-center">
                  {widget && widget.widgetUrl && (
                    <a
                      target="_blank"
                      href={widget.widgetUrl}
                      rel="noreferrer"
                      className="text-white text-[16px] "
                    >
                      <FaExternalLinkSquareAlt />
                    </a>
                  )}
                  <div>
                    {widget ? (
                      <LastSeen date={new Date(widget.updatedAt * 1000)} />
                    ) : (
                      <div>
                        <Skeleton
                          width={100}
                          height={20}
                          baseColor="#47bbb3"
                          highlightColor="#13827a"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </ResponsiveReactGridLayout>
      ) : (
        ""
      )}
    </div>
  );
};
