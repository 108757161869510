import { WidgetType } from "../../types/widgets";
import { FC } from "react";

interface LineInterface {
  widget: WidgetType;
}

export const TableWidget: FC<LineInterface> = ({ widget }) => {
  const columns: string[] = [];

  if (widget.tableValues.length > 0) {
    const firstRow = widget.tableValues[0];
    for (const key in firstRow) {
      if (firstRow.hasOwnProperty(key)) {
        columns.push(key);
      }
    }
  }

  const rows = widget.tableValues;

  return (
    <div className="h-[90%] w-[100%] z-10 bg-white flex flex-col justify-center items-center">
      <h2 className="text-white text-lg">{widget.title}</h2>
      <table className="min-w-full">
        <thead>
          <tr className="bg-[#ebe9e9]">
            {columns.map((column, index) => (
              <th key={index}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((column, columnIndex) => (
                <td key={columnIndex}>{row[column]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
